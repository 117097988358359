import React, { useEffect, useState } from "react";
import "./contactus.scss";
import Banner from "../../components/Banner/Banner";
import {
  callcontact,
  clockcontact,
  desktopban,
  dropddownicon,
  locationcontact,
  mailcontact,
  mobileban,
} from "../../images";
import AOS from "aos";
import "aos/dist/aos.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import { useNavigate } from "react-router-dom";

const tabsArr = [
  { id: 1, title: "INQUIRY" },
  { id: 2, title: "CAREERS" },
];
const ContactUs = () => {
  const { width } = useWindowDimensions();
  const [rightList, setRightList] = useState(0);

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");

  const navigate = useNavigate();

  let base_url = config.api_url + "forms/";

  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    phone: Joi.string().required(),

    message: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    if (rightList === 0) {
      base_url = base_url + "contact";
    } else {
      base_url = base_url + "career";
    }
    setLoading(true);

    const { data } = await http.post(base_url, {
      ...values,
      phone: `${countryCode}${values.phone}`,
    });

    if (data) {
      // redirect
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const fetchCodes = async () => {
    const { data } = await http.get(`${config.api_url}countries/codes`);
    console.log(data);
    if (data) {
      setCodes(data);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  const leftTabsList = tabsArr.length
    ? tabsArr.map((item, i) => (
        <>
          <li
            key={item.id}
            className={`lefttab ${rightList === i ? "active" : null}`}
            onClick={() => setRightList(i)}
          >
            {item.title}
            <span class="pic arrow-up"></span>
          </li>
        </>
      ))
    : null;

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner
        imgSrc={width > 600 ? desktopban : mobileban}
        linkTwoText="Contact Us"
        linkTwoDisabled
        headingText="Contact Us"
      />
      <section className="contact_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-5 left_section order-lg-2">
              <div className="text_container">
                <div className="heading">Get In Touch</div>
                <div className="icon_desc locationdesc">
                  <img
                    src={locationcontact}
                    className="phone_icon"
                    alt="phone icon"
                  />
                  <div className="phone_box">
                    <p className="phone_desc">
                      <a
                        href="https://goo.gl/maps/Xog8HRkrSDTo9Tac9"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <strong>Popawala Chemicals Pvt. Ltd</strong>
                      </a>
                      <br />
                      Raj Mahal Building, Office No. 18, 4th Floor, 84 Veer
                      Nariman Road, Nr Ambassador Hotel, Churchgate, Mumbai -
                      400020
                    </p>
                  </div>
                </div>

                <div className="icon_desc">
                  <img
                    src={callcontact}
                    className="phone_icon"
                    alt="phone icon"
                  />
                  <div className="phone_box">
                    <p className="phone_desc">
                      <a href="tel:+91 22 22871115">+91 22 22871115</a>
                    </p>
                  </div>
                </div>

                <div className="icon_desc">
                  <img
                    src={mailcontact}
                    className="phone_icon"
                    alt="phone icon"
                  />
                  <div className="phone_box">
                    <p className="phone_desc">
                      <a href="mailto:info@popawala.com">info@popawala.com</a>
                    </p>
                  </div>
                </div>

                <div className="icon_desc">
                  <img
                    src={clockcontact}
                    className="phone_icon"
                    alt="phone icon"
                  />
                  <div className="phone_box">
                    <p className="phone_desc">Mon-Fri : 9:00 am - 5:30 pm</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 right_section order-lg-1">
              <iframe
                className="map_box"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d943.4866033360305!2d72.82461903997991!3d18.933765903888737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1e96fffffff%3A0x9e5566da2438e887!2sPopawala%20Chemical%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1653657997143!5m2!1sen!2sin"
                width="1256"
                height="761"
                title="mapframe"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container">
            <div className="heading">We’d love to hear from you</div>
            <div className="desc">
              Whenever you need us, we're here to assist you. Please fill in the
              details below, <br />
              and we’ll get in touch with you.
            </div>
          </div>
          <div className="tabs_content_box">
            <div className="left_section">
              <ul>{leftTabsList}</ul>
            </div>
            <div className="rightbox">
              <div className="form_container">
                <form className="form-group" onSubmit={handleSubmit}>
                  <div className="row form_row">
                    <div className="col-md-6 col-lg-6">
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          //   value={values.name}
                          //   onChange={handleChange}
                        />
                        <label>Name*</label>
                        <div className="error">{formError.name}</div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          //   value={values.email}
                          //   onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                    </div>
                    {/* {rightList === 0 ? (
                      <>
                        <div className="col-md-6 col-lg-6">
                          <div className="material-textfield">
                            <input placeholder=" " type="text" name="inquiry" />
                            <label>Inquiry*</label>
                            <div className="error">
                              error text will look like this*
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-6 col-lg-6">
                          <div className="material-textfield">
                            <input placeholder=" " type="text" name="career" />
                            <label>Careers*</label>
                            <div className="error">
                              error text will look like this*
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}

                    <div className="col-md-6 col-lg-6">
                      <div className="material-textfield">
                        {/* <input placeholder=" " type="number" name="phone" />
                        <label>Phone Number*</label>
                        <div className="error">
                          error text will look like this*
                        </div> */}
                        <div className="mobile_no_flex">
                          <div className="cc_box">
                            <select
                              className="cc_dd"
                              name="countryCode"
                              id="countryCode"
                              value={countryCode}
                              onChange={(e) => setcountryCode(e.target.value)}
                            >
                              {codes.map((item) => {
                                return (
                                  <option value={`+${item.phonecode}`}>
                                    {`+${item.phonecode}`}
                                  </option>
                                );
                              })}
                              {/* <option value="+91" defaultValue>
                                + 91
                              </option>
                              <option value="+93">+ 93</option> */}
                            </select>
                            <img
                              className="dropwdown_icon"
                              width={9}
                              height={8}
                              loading="lazy"
                              alt="dropdown icon"
                              src={dropddownicon}
                            />
                          </div>
                          <div className="no_box">
                            <input
                              placeholder=" "
                              type="number"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                            />
                            <label>Phone Number*</label>
                            <div className="error">{formError.phone}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 message_box">
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <label>Message*</label>
                        <div className="error">{formError.message}</div>
                      </div>
                    </div>

                    <div className="cta_wrapper">
                      <button className="submit_btn" type="submit">
                        {loading ? "Sending.." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
