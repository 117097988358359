import React from "react";
import "./common.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Homepage from "./pages/Homepage/Homepage";
import Products from "./pages/Products/Products";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import ThankYou from "./pages/ThankYou/ThankYou";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

const DefaultLayout = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        {/* <Route exact path="/our-team" element={<OurTeam />} /> */}
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/products" element={<Products />} />
        {/* 
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/404" element={<Notfound />} /> */}
        {/* <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        /> */}
        <Route exact path="/thank-you" element={<ThankYou />} />
        <Route exact path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Footer />
    </>
  );
};

export default DefaultLayout;
