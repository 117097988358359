import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { newlogo } from "../../images";
import "./header.scss";
import { ReactComponent as Fbgrey } from "../../images/Header/facebook (1) (1).svg";
import { ReactComponent as Linkgrey } from "../../images/Header/linkedin (1).svg";
import { ReactComponent as Instagrey } from "../../images/Footer/Group 3124.svg";
import HeaderNavItem from "./HeaderNavItem";
const body = document.querySelector("body");

const navLinksArr = [
  {
    id: 1,
    type: "img",
    img: newlogo,
    link: "/",
    class: "logo_img",
  },
  {
    id: 2,
    type: "text",
    title: "About Us",
    link: "/about-us",
  },
  // {
  //   id: 3,
  //   type: "text",
  //   title: "Our Team",
  //   link: "/our-team",
  // },
  {
    id: 4,
    type: "text",
    title: "Products",
    link: "/products",
  },
  {
    id: 5,
    type: "text",
    title: "Contact Us",
    link: "/contact-us",
  },
];

const navDataArr = [
  {
    id: 1,
    mainTitle: "Home",
    mainLink: "/",
  },
  { id: 2, mainTitle: "About Us", mainLink: "/about-us" },
  // { id: 3, mainTitle: "Our Team", mainLink: "/our-team" },
  { id: 4, mainTitle: "Products", mainLink: "/products" },
  { id: 5, mainTitle: "Contact Us", mainLink: "/contact-us" },
];

const Header = () => {
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const { width } = useWindowDimensions();
  const { pathname: locationPathname } = useLocation();
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    body.style.overflow = "auto";
    setIsMenuActive(false);
  }, [locationPathname]);

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const navlinkList = navLinksArr.map((nav, i) => (
    <>
      <li>
        {nav.type === "text" ? (
          <div
            key={nav.id}
            onClick={() => setNavlinkIndex(i)}
            className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
          >
            <NavLink className={nav.class} to={nav.link}>
              {nav.title}
            </NavLink>
            {/* <a href={nav.link}>{nav.title}</a> */}
          </div>
        ) : (
          <div
            key={nav.id}
            onClick={() => setNavlinkIndex(i)}
            className={`logo_wrapper ${navlinkIndex === i ? "" : ""} `}
          >
            <a href={nav.link}>
              <img
                className={nav.class}
                src={nav.img}
                alt="popawala img"
                onClick={scrollHandler}
              />
            </a>
            {/* <a href={nav.link}>{nav.title}</a> */}
          </div>
        )}
      </li>
    </>
  ));

  return (
    <>
      <header className="header_sec1">
        {width > 700 ? (
          <div className="my_container">
            {/* <div className="logo_wrapper">
              <NavLink to="/" className="logo_link">
                <img
                  className="logo_img"
                  src={newlogo}
                  alt="popawala logo"
                  loading="lazy"
                  width="372.67"
                  height="64.32"
                  onClick={scrollHandler}
                />
              </NavLink>
            </div> */}
            <div className="navlinks">
              <ul>{navlinkList}</ul>
            </div>
          </div>
        ) : (
          <header className="header">
            <div className="logo_wrapper">
              <Link className="logo_link" to="/">
                <img
                  src={newlogo}
                  alt="popawala logo"
                  className="popawala_logo"
                  loading="lazy"
                />
              </Link>
            </div>
            <nav className="nav_links_wrapper">
              <div
                className={`nav_line2_wrapper_flex_wrapper ${
                  isMenuActive ? "active" : ""
                }`}
              >
                <ul className="nav_line2_wrapper_flex">
                  {navDataArr.map((navData, i) => (
                    <HeaderNavItem
                      navData={navData}
                      key={navData.id}
                      arrIndex={i}
                      handleNavIndex={handleNavIndex}
                      navActiveIndex={navActiveIndex}
                    />
                  ))}
                </ul>
                {width <= 1023 && (
                  <div className="nav_social_media_icons">
                    <a
                      href="https://instagram.com/popawalachemicalspvtltd?igshid=YmMyMTA2M2Y="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagrey className="nav_social_icons" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/popawala-chemicals/ "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Linkgrey className="nav_social_icons" />
                    </a>
                    <a
                      href="https://www.facebook.com/popawalachemicalspvtltd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Fbgrey className="nav_social_icons" />
                    </a>
                    {/* <a href="#" target="_blank" rel="noreferrer">
                      <Twittergrey className="nav_social_icons" />
                    </a> */}
                    <div className="follow_us_text">
                      <a href="tel:+91 22 22871115">+91 22 22871115 |</a>
                      <a href="mailto:info@popawala.com"> info@popawala.com</a>
                    </div>
                  </div>
                )}
              </div>
            </nav>

            {/* mobile UI start */}
            <div
              className={`hamburger_lines ${isMenuActive ? "active" : ""}`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </header>
        )}
      </header>
    </>
  );
};

export default Header;
