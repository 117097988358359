import React, { useEffect } from "react";
import "./PageNotFound.scss";
import { logo404 } from "../../images";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const PageNotFound = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="not_found_sec" data-aos="fade">
        <h1 className="oops_text">Oops! That Page Can’t Be Found.</h1>
        <img src={logo404} alt="404 logo" className="logo404" />
        <p className="not_desc">THE PAGE YOU ARE LOOKING FOR DOES NOT EXIst</p>
        <div className="not_return">
          Please return to{" "}
          <Link to="/" className="link_to_home">
            Homepage
          </Link>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
