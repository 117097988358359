import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import {
  chempowder,
  chempowdermb,
  desktopban,
  minusicon,
  mobileban,
  plusicon,
} from "../../images";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./products.scss";
import CommonForm from "../../components/CommonForm/CommonForm";
import AOS from "aos";
import "aos/dist/aos.css";

const tabsArr = [
  { id: 1, title: "BILLIONS™ BLR- 886" },
  { id: 2, title: "Billions™ LR- 108" },
  { id: 3, title: "Billions™ BLR- 895" },
  { id: 4, title: "Billions™ R- 996" },
];

const rightArr = [
  {
    title: "Key Features :",
    feature: [
      {
        list: "Excellent dispresion",
      },
      {
        list: "Good processing, minimal effect on melt-flow",
      },
    ],
    title2: "Recommended Applications :",
    recommendation: [
      {
        list: "Polyolefin masterbatch",
      },
      {
        list: "High temperature extrusion coatings & cast films",
      },
      {
        list: "Engineering plastics",
      },
    ],
    desc: "BILLIONS™ BLR- 886 pigment is a rutile titanium dioxide pigment for plastics, produced by the chloride process. It is designed to deliver bright, white color with excellent processability and lacing resistance.",
  },
  {
    title: "Key Features :",
    feature: [
      {
        list: "High Heat Stability Low oil absorption",
      },
      {
        list: "High opacity and whiteness",
      },
      {
        list: "Low Moisture Absorption",
      },
      {
        list: "Low Reactivity With Antioxidants",
      },
      {
        list: "Excellent Compatibility With Plastic Resins",
      },
      {
        list: "Rapid And Complete Dispersion",
      },
    ],
    title2: "Recommended Applications :",
    recommendation: [
      {
        list: "Masterbatch & Compounds",
      },
      {
        list: "Polyolefin & PVC Film",
      },
      {
        list: "Plastics With High Thermal Stability",
      },
    ],
    desc: "BILLIONS™ LR-108 pigment is an alumina treated rutile titanium dioxide pigment made by the sulfate process. It is specially designed for masterbatch and compounding applications.<br /><br />BILLIONS™ R-108 pigment disperses easily in polyolefins, with minimal effect on melt flow index, so that even masterbatch with high Ti02 concentration can produce film with high opacity and whiteness.<br /><br />BILLIONS™ LR-108 pigment is recommended for plastics applications that require high thermal stability. It is designed with a hydrophobic surface that acts as a barrier to help prevent the pigment from absorbing moisture from the air.",
  },
  {
    title2: "",
    recommendation: [],
    title: "Recommended Applications :",
    feature: [
      {
        list: "Interior architectural coatings",
      },
      {
        list: "Exterior architectural coatings",
      },
      {
        list: "Industrial coatings",
      },
    ],
    desc: "Billions™ BLR-895 pigment is a coatings grade titanium dioxide pigment manufactured by the chloride process. It is recommended for a range of coatings applications.<br /><br />Billions™ BLR-895 pigment has outstanding dispersion performance, dispersing quickly and easily. It also has excellent opacity and gloss, and delivers a brilliant whiteness with a clean blue tone. With its carefully selected inorganic surface coating, Billions™ BLR-895 pigment provides a high level of weather ability, making it flexible across both interior and exterior applications.",
  },
  {
    title: "Key Features :",
    feature: [
      {
        list: "Industrial coatings",
      },
      {
        list: "Powder coatings",
      },
      {
        list: "External & internal architectural coatings",
      },
    ],
    title2: "Recommended Applications :",
    recommendation: [
      {
        list: "High durability",
      },
      {
        list: "High opacity",
      },
      {
        list: "Good brightness",
      },
      {
        list: "Good dispersibility",
      },
    ],
    desc: "Billions™ R-996 pigment is a zirconia and alumina treated rutile titanium dioxide pigment produced by the sulfate process. It isa highly durable, versatile pigment suitable for a wide range of applications.<br /><br />Careful control of the Ti02 particle size during the manufacturing process of Billions™ R-996 pigment results in good brightness, high opacity and easy dispersibility.",
  },
];

const Products = () => {
  const { width } = useWindowDimensions();
  const [rightList, setRightList] = useState(0);

  const leftTabsList = tabsArr.length
    ? tabsArr.map((item, i) => (
        <li
          key={item.id}
          className={`lefttab ${rightList === i ? "active" : null}`}
          onClick={() => setRightList(i)}
        >
          {item.title}
          {/* <img alt="popawala img"
            className="arrow_right"
            src={arrowright}
            alt="caterina arrow img"
          /> */}
          <span class="pic arrow-up"></span>
        </li>
      ))
    : null;

  const mbMainAccList = tabsArr.length
    ? tabsArr.map((item, i) => (
        <>
          <li
            className={`mb_main_li ${rightList === i ? "active" : null}`}
            onClick={() => setRightList(i)}
          >
            {item.title}
            <img
              alt="popawala img"
              className="plus_minus"
              src={rightList === i ? minusicon : plusicon}
            />
          </li>
          <ul
            className={`mb_faq_sub_menu--list_wrapper ${
              rightList === i ? "active" : null
            }`}
          >
            <div className="col-md-7 col-lg-7 rightbox">
              <div
                className="first_desc"
                dangerouslySetInnerHTML={{
                  __html: rightArr[rightList]["desc"],
                }}
              ></div>
              <div className="feature_wrapper">
                <div className="feature_title">
                  {rightArr[rightList]["title"]}
                </div>
                {rightArr[rightList]["feature"].map((item) => {
                  return (
                    <div
                      className="title_list"
                      dangerouslySetInnerHTML={{ __html: item.list }}
                    ></div>
                  );
                })}
              </div>
              <div className="recommendation_wrapper">
                <div className="recommendation_title">
                  {rightArr[rightList]["title2"]}
                </div>
                {rightArr[rightList]["recommendation"].map((item) => {
                  return (
                    <div
                      className="recommendation_list"
                      dangerouslySetInnerHTML={{ __html: item.list }}
                    ></div>
                  );
                })}
              </div>
            </div>
          </ul>
        </>
      ))
    : null;

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner
        imgSrc={width > 600 ? desktopban : mobileban}
        linkTwoText="products"
        linkTwoDisabled
        headingText="products"
      />

      <section className="product_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="row product_boxes">
            <div className="col-lg-6">
              <div className="text_container">
                <div className="breadcrumb_text">ABOUT Tio2</div>
                <h4 className="heading">
                  Titanium Dioxide is a bright white inorganic compound
                </h4>
                <p className="desc">
                  Titanium dioxide (TiO2) is used primarily as a vivid colorant
                  in a wide array of everyday products. With its ultra-white
                  color, ability to scatter light and UV resistance, TiO2 is
                  enormously popular within the industrial and consumer sectors.
                  It is a reliant ingredient because of its non-toxic,
                  non-reactive and luminous features, which safely enhance the
                  whiteness and brightness of a wide range of materials. Paints,
                  catalytic coatings, plastics, paper, pharmaceuticals,
                  sunscreen, packaging, commercial printing inks, etc. are some
                  of its applications.
                </p>
                <div className="image_container">
                  {width < 600 ? (
                    <img
                      alt="popawala img"
                      className="chemical_img"
                      width="860"
                      height="588"
                      src={chempowdermb}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image_container">
                {width > 600 ? (
                  <img
                    alt="popawala img"
                    className="chemical_img"
                    width="860"
                    height="588"
                    src={chempowder}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product_sec2" data-aos="fade-up">
        <div className="my_container p-0">
          <div className="subheading_box">
            <div className="breadcrumb_text">OUR PRODUCTS</div>
            <div className="heading">
              Explore And Learn About Our Range Of&nbsp;
              <br />
              Products Down Below:
            </div>
          </div>
          {width > 767 ? (
            <div className="row">
              <div className="left_section">
                <ul>{leftTabsList}</ul>
              </div>
              <div className="row rightbox">
                <div className="col-lg-5">
                  <div
                    className="first_desc"
                    dangerouslySetInnerHTML={{
                      __html: rightArr[rightList]["desc"],
                    }}
                  ></div>
                </div>
                <div className="col-lg-3">
                  <div className="feature_wrapper">
                    <div className="feature_title">
                      {rightArr[rightList]["title"]}
                    </div>
                    {rightArr[rightList]["feature"].map((item) => {
                      return (
                        <div
                          className="title_list"
                          dangerouslySetInnerHTML={{ __html: item.list }}
                        ></div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="recommendation_wrapper">
                    <div className="recommendation_title">
                      {rightArr[rightList]["title2"]}
                    </div>
                    {rightArr[rightList]["recommendation"].map((item) => {
                      return (
                        <div
                          className="recommendation_list"
                          dangerouslySetInnerHTML={{ __html: item.list }}
                        ></div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mb_faq" data-aos="fade-up">
              <ul className="mb_main_wrapper">{mbMainAccList}</ul>
            </div>
          )}
        </div>
      </section>

      <section className="product_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text_container">
                <div className="breadcrumb_text">PRODUCT DETAIL</div>
                <div className="heading">
                  Product Information, Application And&nbsp;
                  <br />
                  Technical Data
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text_container">
                <div className="desc">
                  Each of our pigments has unique qualities and is intended to
                  provide performance advantages in specific applications. To
                  learn more about the technical detail and applications, please
                  refer to the table below.
                </div>
              </div>
            </div>
          </div>
          <ul className="flex_box">
            <li className="table_title">Table Key :</li>
            <li className="highly_title">Highly Recommended</li>
            <li className="recommend_title">Recommended</li>
            <li className="sulphate_title">Sulphate</li>
            <li className="chloride_title">Chloride</li>
          </ul>
          <table className="product_table">
            <table className="product_table">
              <tbody className="prouct_table__body">
                <tr className="prouct_table__row">
                  <td className="blank_heading"></td>
                  <td className="coatings_heading" colspan="3">
                    coatings
                  </td>
                  <td className="plastic_heading" colspan="2">
                    plastics
                  </td>
                  <td className="ink_heading" colspan="2">
                    ink
                  </td>
                </tr>
                <tr className="prouct_table__row">
                  <td className="product_heading">Products</td>
                  <td className="title1">
                    Interior
                    <br />
                    Architectural
                  </td>
                  <td className="title2">
                    Exterior
                    <br />
                    Architectural
                  </td>
                  <td className="title3">Industrial</td>
                  <td className="title4">Masterbatch</td>
                  <td className="title5">
                    PVC
                    <br />
                    Profile
                  </td>
                  <td className="title6">High Gloss</td>
                  <td className="title7">
                    Reverse
                    <br />
                    Laminated
                  </td>
                </tr>
                <tr className="prouct_table__row">
                  <td className="product_name">Billions™ R-996</td>
                  <td className="prod1_feature1"></td>
                  <td className="prod1_feature2"></td>
                  <td className="prod1_feature3"></td>
                  <td className="prod1_feature4"></td>
                  <td className="prod1_feature5"></td>
                  <td className="prod1_feature6"></td>
                  <td className="prod1_feature7"></td>
                </tr>
                <tr className="prouct_table__row">
                  <td className="product_name">Billions™ LR- 108</td>
                  <td className="prod2_feature1"></td>
                  <td className="prod2_feature2"></td>
                  <td className="prod2_feature3"></td>
                  <td className="prod2_feature4"></td>
                  <td className="prod2_feature5"></td>
                  <td className="prod2_feature6"></td>
                  <td className="prod2_feature7"></td>
                </tr>
                <tr className="prouct_table__row">
                  <td className="product_name">Billions™ BLR- 895</td>
                  <td className="prod3_feature1"></td>
                  <td className="prod3_feature2"></td>
                  <td className="prod3_feature3"></td>
                  <td className="prod3_feature4"></td>
                  <td className="prod3_feature5"></td>
                  <td className="prod3_feature6"></td>
                  <td className="prod3_feature7"></td>
                </tr>
                <tr className="prouct_table__row">
                  <td className="product_name">Billions™ BLR- 886</td>
                  <td className="prod4_feature1"></td>
                  <td className="prod4_feature2"></td>
                  <td className="prod4_feature3"></td>
                  <td className="prod4_feature4"></td>
                  <td className="prod4_feature5"></td>
                  <td className="prod4_feature6"></td>
                  <td className="prod4_feature7"></td>
                </tr>
              </tbody>
            </table>
          </table>
        </div>
      </section>

      <section className="product_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="row difference_between">
            <div className="col-lg-4">
              <div className="text_container">
                <div className="breadcrumb_text">SO₄² & Cl</div>
                <h4 className="heading">
                  Difference Between Sulphate And Chloride
                </h4>
                <p className="desc">
                  We supply a range of both sulfate and chloride
                  high-performance TiO2 pigments for all major applications like
                  paints, inks and plastics. To understand the difference
                  between the two, please refer to the table below.
                </p>
                <div className="image_container">
                  {/* {width < 600 ? (
                    <img alt="popawala img"
                      className="chemical_img"
                      width="860"
                      height="588"
                      src={chempowdermb}
                    />
                  ) : null} */}
                </div>
              </div>
            </div>
            <div className="col-lg-8 table_box">
              <div className="diff_table__wrapper">
                <table className="diff_table">
                  <table className="diff_table">
                    <tbody className="diff_table__body">
                      <tr className="diff_table__row">
                        <td className="blank_data"></td>
                        <td className="chloride_title">CHLORIDE</td>
                        <td className="sulphate_title">SULPHATE</td>
                      </tr>
                      <tr className="diff_table__row">
                        <td className="point_name">Particle Size</td>
                        <td className="point1_desc1">
                          The particle size of the chloride <br />
                          process is between 190-250nm.
                        </td>
                        <td className="point1_desc2">
                          While the sulfate process is <br />
                          250-450nm.
                        </td>
                      </tr>
                      <tr className="diff_table__row">
                        <td className="point_name">Color</td>
                        <td className="point2_desc1">
                          It is blue due to the influence <br />
                          of particle size and impurity.
                        </td>
                        <td className="point2_desc2">
                          In the sulfate process, <br />
                          titanium dioxide is yellow.
                        </td>
                      </tr>
                      <tr className="diff_table__row">
                        <td className="point_name">Dispersity</td>
                        <td className="point3_desc1">
                          No significant difference
                        </td>
                        <td className="point3_desc2">
                          No significant difference
                        </td>
                      </tr>
                      <tr className="diff_table__row">
                        <td className="point_name">Hiding Power</td>
                        <td className="point4_desc1">
                          Chloride processed titanium <br />
                          dioxide has better hiding <br />
                          power due to its small <br />
                          particle size.
                        </td>
                        <td className="point4_desc2">
                          The sulfate processed titanium <br />
                          dioxide has less hiding <br />
                          power comparatively.
                        </td>
                      </tr>
                      <tr className="diff_table__row">
                        <td className="point_name no_bottom_border">
                          Products
                        </td>
                        <td className="point5_desc1">
                          Billions™ BLR- 886 &<br />
                          Billions™ BLR- 895.
                        </td>
                        <td className="point5_desc2">
                          Billions™ LR- 108 &<br />
                          Billions™ R-996.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CommonForm />
    </>
  );
};

export default Products;
