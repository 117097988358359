import React, { useEffect, useState } from "react";
import {
  borderline,
  borderlinemb,
  brand1,
  brand2,
  brownLeftArrow,
  brownRightArrow,
  chemhand2,
  cuscenicon,
  homebanner,
  homebannermb,
  reliabilityicon,
  scrolldown,
  trusticon,
} from "../../images";
import "./homepage.scss";
import Button from "../../components/Button/Button";
import useWindowDimensions from "../../hooks/useWindowDimensions";
//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper";
import { NavLink } from "react-router-dom";
import CommonForm from "../../components/CommonForm/CommonForm";
import AOS from "aos";
import "aos/dist/aos.css";
import MetaTags from "../../components/MetaTags/MetaTags";
import Loader from "../../components/Loader/Loader";

const Homepage = () => {
  const { width } = useWindowDimensions();
  const [isLoader, setIsLoader] = useState(true);

  const scrollDown = () => {
    const id = "home_sec3";
    const yOffset = -170;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    window.scrollTo(0, 0);
  }, []);

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };

  return (
    <>
      <div style={{ display: isLoader ? "block" : "none" }}>
        <Loader />
      </div>
      <MetaTags
        metaTitle="Welcome to Popawala"
        metaDesc="Popawala latest website here"
        canonicalLink="https://www.popawala.com/"
      />

      <section className="home_sec1" data-aos="fade">
        <div className="banner_sec">
          {width > 600 ? (
            <img
              alt="popawala img"
              className="banner_img"
              src={homebanner}
              width="1920"
              height="1025"
              onLoad={homebanner ? imageLoaded : null}
            />
          ) : (
            <img
              alt="popawala img"
              className="banner_img"
              src={homebannermb}
              width="375"
              height="702"
              onLoad={homebannermb ? imageLoaded : null}
            />
          )}
          <div className="banner_text">
            INDIA’S LARGEST TITANIUM DIOXIDE SUPPLIER SINCE 1964
          </div>
          <img
            alt="popawala img"
            className="scrolldown_img"
            src={scrolldown}
            onClick={scrollDown}
            width="24.27"
            height="32.22"
          />
          <div className="btn_wrapper">
            <Button removebg btnText="Know More" linkTo="/products" />
          </div>
        </div>
      </section>

      {/* <section className="home_sec2" id="home_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="counter_box__wrapper">
            <div className="counter_box">
              <CounterItem
                startCount={30}
                endCount={50}
                countTitle="Years in Industry"
                plus
              />
            </div>
            <div className="counter_box">
              <CounterItem
                startCount={5}
                endCount={10}
                countTitle="Clientele Reach"
                plus
              />
              <p></p>
            </div>
            <div className="counter_box">
              <CounterItem
                startCount={1000}
                endCount={1200}
                countTitle="TiO2 Packs Sold"
                plus
              />
            </div>
            <div className="counter_box">
              <CounterItem
                startCount={130}
                endCount={150}
                countTitle="Employees"
                plus
              />
            </div>
          </div>
          // <div className="counter_box__wrapper">
          // <div className="count_title">Years in Industry</div>
          // <div className="count_title">Clientele Reach</div>
          // <div className="count_title">TiO2 Packs Sold</div>
          // <div className="count_title">Employees</div>
          // </div>
        </div>
      </section> */}

      <section className="home_sec3" id="home_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text_container">
                <div className="breadcrumb_text">ABOUT US</div>
                <h4 className="heading">
                  India’s Leading Chemical Import And Indenting Company
                </h4>
                <p className="desc">
                  Incepted in 1964, Popawala Chemicals is among the premier,
                  largest and India’s leading suppliers of chemicals. Having
                  spent 50+ years in this industry, we have been a reliable
                  supplier in the paint, plastic and ink industries. Having
                  stock points all over the country has made it cost and time
                  effective for our customers’ all over India. Over the past few
                  decades, we have built and maintained a very strong and
                  personal relationship with all our clients’ and strategically
                  assisted them in their growth.
                </p>
                <div className="image_container">
                  {width < 600 ? (
                    <img
                      alt="popawala img"
                      className="chemical_img"
                      width="860"
                      height="588"
                      src={chemhand2}
                    />
                  ) : null}
                </div>
                <div className="btn_wrapper">
                  {width > 600 ? (
                    <Button removebg btnText="Know More" linkTo="/about-us" />
                  ) : (
                    <Button removebg btnText="Know More" linkTo="/about-us" />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image_container">
                {width > 600 ? (
                  <img
                    alt="popawala img"
                    className="chemical_img"
                    width="860"
                    height="588"
                    src={chemhand2}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec4" data-aos="fade-up">
        <div className="my_container p-0">
          <div className="subheading_box">
            <div className="breadcrumb_text">WHY CHOOSE US?</div>
            <div className="heading">
              Providing Finest Quality Products, Popawala&nbsp;
              <br />
              Chemicals Is The Largest Supplier Of Chemicals.
            </div>
          </div>
          <div className="box_container">
            <Swiper
              modules={[Pagination]}
              pagination={true}
              loop={false}
              className="whyswiper"
              breakpoints={{
                0: {
                  slidesPerView: 1.01,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <div className="content_box">
                  <img
                    alt="popawala img"
                    className="box_icon"
                    width="53.02"
                    height="54.33"
                    src={trusticon}
                  />
                  <div className="heading">trust</div>
                  <div className="desc">
                    With over decades of knowledge, experience, innovation and
                    practicality, we have been a trusted distributor of Titanium
                    Dioxide for over 55 years.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="content_box">
                  <img
                    alt="popawala img"
                    className="box_icon"
                    width="53.02"
                    height="54.33"
                    src={reliabilityicon}
                  />
                  <div className="heading">reliability</div>
                  <div className="desc">
                    Our customers are familiar with our products and have trust
                    in the quality, services and timely delivery of the products
                    regularly.
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="content_box">
                  <img
                    alt="popawala img"
                    className="box_icon"
                    width="53.02"
                    height="54.33"
                    src={cuscenicon}
                  />
                  <div className="heading">customer centric</div>
                  <div className="desc">
                    Our focus has been on meeting and generating solutions for
                    our clients' demands and expectations over the years, aiding
                    us in the development of our company's dependability and
                    trustworthiness.
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className="home_sec5" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text_container">
                <div className="breadcrumb_text">OUR PRODUCTS</div>
                <div className="heading">
                  With Unblinking Eye On Quality, Our&nbsp;
                  <br />
                  Product Portfolio Will Meet Your Purpose.
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text_container">
                <div className="desc">
                  Our products are committed to ensuring the best-in-class
                  quality and excellent features with innovative applications to
                  meet diverse needs. These products provide services to various
                  industries such as ink, plastics and coatings.
                </div>
              </div>
            </div>
          </div>
          <div className="products_swiper">
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={true}
              navigation={{
                prevEl: `.products_swiper .left_arrow`,
                nextEl: `.products_swiper .right_arrow`,
              }}
              slidesPerView="2"
              loop={true}
              className="productsswiper"
              breakpoints={{
                0: {
                  slidesPerView: 1.01,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2,
                },
              }}
            >
              <SwiperSlide>
                <div className="slider_box">
                  <div className="heading">Billions™ BLR-886</div>
                  {width > 600 ? (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="3"
                      height="238"
                      loading="lazy"
                      src={borderline}
                    />
                  ) : (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="238"
                      height="2"
                      loading="lazy"
                      src={borderlinemb}
                    />
                  )}
                  <div className="desc_wrapper">
                    <div className="desc">
                      Billions™ R-886 BLR-886 pigment is a rutile titanium
                      dioxide pigment for plastics, produced by the chloride
                      process. It is designed to deliver bright, white color
                      with excellent processability and lacing resistance.
                    </div>
                    <div className="cta_wrapper">
                      <NavLink className="know_more" to="/products">
                        Know More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider_box">
                  <div className="heading">Billions™ BLR- 895</div>
                  {width > 600 ? (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="3"
                      height="238"
                      loading="lazy"
                      src={borderline}
                    />
                  ) : (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="238"
                      height="2"
                      loading="lazy"
                      src={borderlinemb}
                    />
                  )}
                  <div className="desc_wrapper">
                    <div className="desc">
                      Billions™ BLR- 895 pigment is a coatings grade titanium
                      dioxide pigment manuractured by the chloride process. It
                      iS recommended tor a range or coatings applications.
                    </div>
                    <div className="cta_wrapper">
                      <NavLink className="know_more" to="/products">
                        Know More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider_box">
                  <div className="heading">Billions™ LR-108</div>
                  {width > 600 ? (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="3"
                      height="238"
                      loading="lazy"
                      src={borderline}
                    />
                  ) : (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="238"
                      height="2"
                      loading="lazy"
                      src={borderlinemb}
                    />
                  )}
                  <div className="desc_wrapper">
                    <div className="desc">
                      Billions™ LR-108 pigment is an alumina treated rutile
                      titanium dioxide pigment made by the sulfate process. It
                      is specially designed for masterbatch and compounding
                      applications.
                    </div>
                    <div className="cta_wrapper">
                      <NavLink className="know_more" to="/products">
                        Know More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider_box">
                  <div className="heading">Billions™ R-996</div>
                  {width > 600 ? (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="3"
                      height="238"
                      loading="lazy"
                      src={borderline}
                    />
                  ) : (
                    <img
                      alt="popawala img"
                      className="border_line"
                      width="238"
                      height="2"
                      loading="lazy"
                      src={borderlinemb}
                    />
                  )}
                  <div className="desc_wrapper">
                    <div className="desc">
                      Billions™ R-996 pigment is a zirconia and alumina treated
                      rutile titanium dioxide pigment produced by the sulfate
                      process. It is a highly durable, versatile pigment
                      suitable for a wide range of applications.
                    </div>
                    <div className="cta_wrapper">
                      <NavLink className="know_more" to="/products">
                        Know More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="navigation_arrows_wrapper">
              <div className="navigation_arrow left_arrow">
                <img
                  src={brownLeftArrow}
                  width="38"
                  height="38"
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
              <div className="navigation_arrow right_arrow">
                <img
                  src={brownRightArrow}
                  width="38"
                  height="38"
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="home_sec6" data-aos="fade-up">
        <div className="my_container">
          <div className="testimonials_swiper">
            <Swiper
              modules={[Navigation, Autoplay]}
              navigation={{
                prevEl: `.testimonials_swiper .navigation_left`,
                nextEl: `.testimonials_swiper .navigation_right`,
              }}
              autoplay={{
                delay: 4000,
              }}
              slidesPerView="1"
              loop={true}
              className="testimonialsswiper"
            >
              <SwiperSlide>
                <div className="testimonial_box">
                  <div className="breadcrumb_text">testimonials</div>
                  <div className="heading">What our clients say</div>
                  <img
                    alt="popawala img"
                    className="quote_icon"
                    width="59.33"
                    height="36.54"
                    src={quoteicon}
                  />
                  <div className="desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </div>
                  <img
                    alt="popawala img"
                    className="underline_icon"
                    width="45"
                    height="2"
                    src={underline}
                  />
                  <div className="testimonial_name">Jonathan Doe</div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="navigations">
              <div className="navigation_left">PREV</div>
              <div className="navigation_right">NEXT</div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="home_sec7" data-aos="fade-up">
        <div className="my_container">
          <div className="row desc_brand_row">
            <div className="col-lg-6">
              <div className="text_container">
                <div className="breadcrumb_text">OUR PARTNERS</div>
                <div className="heading">
                  Partnering With The Best To Deliver&nbsp;
                  <br />
                  Exceptional Quality And Value
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="brand_swiper">
                <Swiper
                  slidesPerView="2"
                  loop={false}
                  // spaceBetween="50"
                  className="brandswiper"
                >
                  <SwiperSlide>
                    <>
                      <img
                        alt="popawala img"
                        className="brand_img1"
                        width="284"
                        height="75"
                        src={brand1}
                      />
                    </>
                  </SwiperSlide>
                  <SwiperSlide>
                    <>
                      <img
                        alt="popawala img"
                        className="brand_img2"
                        width="312"
                        height="76"
                        src={brand2}
                      />
                    </>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CommonForm />
    </>
  );
};

export default Homepage;
