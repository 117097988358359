import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  callicon,
  emailicon,
  facebookicon,
  linkedinicon,
  locationicon,
  logo,
  scrollup,
  instaicon,
} from "../../images";
import "./footer.scss";

const Footer = () => {
  const { width } = useWindowDimensions();

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <section className="footer_sec1">
        <img
          className="scrollup_img"
          src={scrollup}
          alt="popawala arrow"
          loading="lazy"
          width="74"
          height="74"
          onClick={scrollHandler}
        />
        <div className="my_container">
          <div className="row">
            <div className="col-md-5 col-lg-5 social_icon_box">
              <div className="logo_social">
                <img
                  alt="popawala img"
                  className="footer_logo"
                  src={logo}
                  width="311.8"
                  height="53.83"
                />
                {width > 767 ? (
                  <ul className="social_list">
                    <a
                      href="https://www.linkedin.com/company/popawala-chemicals/ "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className="list_item">
                        <img
                          alt="popawala img"
                          className="icons"
                          width="12.92"
                          height="13.69"
                          src={linkedinicon}
                        />
                      </li>
                    </a>
                    <a
                      href="https://instagram.com/popawalachemicalspvtltd?igshid=YmMyMTA2M2Y="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className="list_item">
                        <img
                          alt="popawala img"
                          className="icons"
                          width="23.24"
                          height="20"
                          src={instaicon}
                        />
                      </li>
                    </a>
                    <a
                      href="https://www.facebook.com/popawalachemicalspvtltd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className="list_item">
                        <img
                          alt="popawala img"
                          className="icons facebook"
                          width="14.29"
                          height="13"
                          src={facebookicon}
                        />
                      </li>
                    </a>
                    {/* <a href="www.google.com" target="_blank" rel="noreferrer">
                      <li className="list_item">
                        <img alt="popawala img"
                          className="icons"
                          width="20"
                          height="19.98"
                          src={twittericon}
                        />
                      </li>
                    </a> */}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="links_sec">
                <div className="links_flex">
                  <h4 className="link_heading">Links</h4>
                  <ul className="link_list">
                    <Link to="/about-us">
                      <li className="list_item">About Us</li>
                    </Link>
                    {/* <Link to="/our-team">
                      <li className="list_item">Our Team</li>
                    </Link> */}
                    <Link to="/products">
                      <li className="list_item">Products</li>
                    </Link>
                    {/* <Link to="/terms-and-conditions">
                      <li className="list_item">Terms & Conditions</li>
                    </Link> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="connect_sec">
                <div className="connect_flex">
                  <h4 className="connect_heading">CONNECT WITH US</h4>
                  <ul className="connect_list">
                    <li className="list_item addressfield">
                      <img
                        alt="popawala img"
                        className="connect_icons"
                        width="21.43"
                        height="30.61"
                        src={locationicon}
                      />
                      <p className="list_desc">
                        Raj Mahal Building, Office No. 18, 4th Floor, 84 Veer
                        Nariman Road, Nr Ambassador Hotel, Churchgate, Mumbai -
                        400020
                      </p>
                    </li>
                    <a href="tel:+91 22 22871115">
                      <li className="list_item contact_no_list">
                        <img
                          alt="popawala img"
                          className="connect_icons"
                          width="21.43"
                          height="30.61"
                          src={callicon}
                        />
                        <p className="list_desc contactno">+91 22 22871115</p>
                      </li>
                    </a>
                    <a href="mailto:info@popawala.com">
                      <li className="list_item">
                        <img
                          alt="popawala img"
                          className="connect_icons"
                          width="21.43"
                          height="30.61"
                          src={emailicon}
                        />
                        <p className="list_desc emailid">info@popawala.com</p>
                      </li>
                    </a>
                  </ul>
                </div>
                {width < 767 ? (
                  <ul className="social_list">
                    <a
                      href="https://www.linkedin.com/company/popawala-chemicals/ "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className="list_item">
                        <img
                          alt="popawala img"
                          className="icons"
                          width="20"
                          height="19.98"
                          src={linkedinicon}
                        />
                      </li>
                    </a>
                    <a
                      href="https://instagram.com/popawalachemicalspvtltd?igshid=YmMyMTA2M2Y="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className="list_item">
                        <img
                          alt="popawala img"
                          className="icons"
                          width="20"
                          height="19.98"
                          src={instaicon}
                        />
                      </li>
                    </a>
                    <a
                      href="https://www.facebook.com/popawalachemicalspvtltd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li className="list_item">
                        <img
                          alt="popawala img"
                          className="icons"
                          width="20"
                          height="19.98"
                          src={facebookicon}
                        />
                      </li>
                    </a>
                    {/* <a href="www.google.com" target="_blank" rel="noreferrer">
                      <li className="list_item">
                        <img alt="popawala img"
                          className="icons"
                          width="20"
                          height="19.98"
                          src={twittericon}
                        />
                      </li>
                    </a> */}
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer_sec2">
        <div className="my_container">
          <div className="copyright_flex">
            <p>
              Copyright 2022 Popawala Chemicals Pvt. Ltd. | Crafted by{" "}
              <a
                href="https://www.togglehead.in/"
                target="_blank"
                rel="noreferrer"
              >
                Togglehead
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
