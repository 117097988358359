import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import "./common.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import { whatsapp } from "./images";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
      <div className="whatsapp_wrapper">
        <a href="https://wa.me/+919029944485" target="_blank" rel="noreferrer">
          <img className="whatsapp_icon" src={whatsapp} alt="whatsapp icon" />
        </a>
      </div>
    </>
  );
}

export default App;
