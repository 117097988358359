import React, { useEffect, useState } from "react";
import "./commonform.scss";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const CommonForm = () => {
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let base_url = config.api_url + "forms/footer";

  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="comform_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="heading">Get In Touch</div>
          <div className="form_container">
            <form className="form-group" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <div className="material-textfield">
                    <input
                      placeholder=" "
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <label>Name*</label>
                    <div className="error">{formError.name}</div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="material-textfield">
                    <input
                      placeholder=" "
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <label>E-mail ID*</label>
                    <div className="error">{formError.email}</div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="cta_wrapper">
                    <button className="submit_btn" type="submit">
                      {loading ? "Sending.." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonForm;
