import React, { useEffect } from "react";
import "./ThankYou.scss";
import { thanklogo } from "../../images";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import CommonForm from "../../components/CommonForm/CommonForm";

const ThankYou = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="thankyousec" data-aos="fade">
        <img src={thanklogo} alt="Thank you logo" className="thanklogo" />
        <h1 className="thank_heading">Thank you</h1>
        <p className="thank_desc">For Getting In Touch</p>
        <div className="thank_return">
          Please return to{" "}
          <Link to="/" className="link_to_home">
            Homepage
          </Link>
        </div>
      </div>

      <CommonForm />
    </>
  );
};

export default ThankYou;
