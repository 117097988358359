import React from "react";
import { NavLink } from "react-router-dom";
import "./button.scss";

const Button = ({ linkTo, internalLink, btnText }) => {
  if (internalLink) {
    return (
      <NavLink to={linkTo ? linkTo : "/"} className="common_btn">
        {btnText}
      </NavLink>
    );
  }
  return (
    <a href={linkTo ? linkTo : null} className="common_btn">
      {btnText}
    </a>
  );
};

export default Button;
