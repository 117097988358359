import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  aboutus,
  certificate1,
  certificate2,
  desktopban,
  mapsvg,
  mapsvgmobnew,
  missionicon,
  mobileban,
  personicon,
} from "../../images";
import "./aboutus.scss";
import AOS from "aos";
import "aos/dist/aos.css";
//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper";
import CommonForm from "../../components/CommonForm/CommonForm";

const AboutUs = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner
        imgSrc={width > 600 ? desktopban : mobileban}
        linkTwoText="about us"
        linkTwoDisabled
        headingText="about us"
      />

      <section className="about_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="row about_us_section">
            <div className="col-lg-6">
              <div className="text_container">
                <div className="breadcrumb_text">ABOUT US</div>
                <h4 className="heading">
                  India’s Leading Chemical Import And Indenting Company
                </h4>
                <p className="desc">
                  We are India’s most reputed and the largest Titanium Dioxide
                  distributor with over 50+ years of experience in the chemical
                  industry. Established in 1964, we have been a reliable
                  supplier in the paint, plastic and ink industries. Having
                  stock points all over the country has made it cost and time
                  effective for our customers all over India. Over the past few
                  decades, we have built and maintained a very strong and
                  personal relationship with all our clients’ and strategically
                  assisted them in their growth.
                </p>
                <div className="mission_container">
                  <img
                    alt="popawala img"
                    className="mission_icon"
                    width="20.65"
                    height="20.65"
                    src={missionicon}
                  />
                  <div className="mission_heading">Mission</div>
                </div>
                <div className="mission_desc">
                  Our mission is to fuel the growth of our customers and
                  suppliers through our strong network in the chemical industry
                  while maintaining trust and reputation. Titanium Dioxide being
                  at the core of our business, we will ensure the best quality
                  and price for our strategic partners and customers.
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image_container">
                <img
                  alt="popawala img"
                  className="aboutus_img"
                  width="860"
                  height="588"
                  src={aboutus}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row stock_section">
            <div className="col-lg-5 order-lg-2">
              <div className="text_container">
                <div className="breadcrumb_text">Stock Locations</div>
                <h4 className="heading">
                  With Supply Chain Presence Across Top Metropolitan Cities, We
                  Conquer The Markets
                </h4>
                <p className="desc">
                  Our warehouses are strategically positioned to assist our
                  clients in better managing their businesses. These location
                  provides a great impetus for effectively managing the
                  distribution.
                </p>
                <ul className="city_list">
                  <li className="list_item">Mumbai</li>
                  <li className="list_item">Chennai</li>
                  <li className="list_item">Delhi</li>
                  <li className="list_item">Kolkata</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="image_container">
                {width > 600 ? (
                  <img
                    alt="popawala img"
                    className="map_img"
                    width="860"
                    height="588"
                    src={mapsvg}
                  />
                ) : (
                  <img
                    alt="popawala img"
                    className="map_img"
                    width="860"
                    height="588"
                    src={mapsvgmobnew}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text_container">
                <div className="breadcrumb_text">OUR TEAM</div>
                <div className="heading">
                  A Place Where Dreams And Teams Work Together
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text_container">
                <div className="desc">
                  The team at Popawala Chemicals is a tight-knit, talented group
                  with a shared vision of delivering consistently great results
                  and services for our clients. Their uncompromised commitment
                  to quality, sustainability, innovation and technology has
                  positioned them to set new benchmarks.
                </div>
              </div>
            </div>
          </div>
          <div className="team_box">
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={true}
              className="team_swiper"
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              <SwiperSlide>
                <div className="content_box">
                  <img
                    alt="popawala img"
                    className="profile_icon"
                    width="53.02"
                    height="54.33"
                    src={personicon}
                  />
                  <div className="heading">Mr. Balkrishna Popawala</div>
                  <div className="desc">Chairman</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="content_box">
                  <img
                    alt="popawala img"
                    className="profile_icon"
                    width="53.02"
                    height="54.33"
                    src={personicon}
                  />
                  <div className="heading">Mr. HIREN Popawala</div>
                  <div className="desc">Managing Director</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="content_box">
                  <img
                    alt="popawala img"
                    className="profile_icon"
                    width="53.02"
                    height="54.33"
                    src={personicon}
                  />
                  <div className="heading">Mr. Akshay Popawala</div>
                  <div className="desc">Director</div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="content_box">
                  <img
                    alt="popawala img"
                    className="profile_icon"
                    width="53.02"
                    height="54.33"
                    src={personicon}
                  />
                  <div className="heading">Mr. RAHUL Popawala</div>
                  <div className="desc">Director</div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className="about_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="row adjusted_row">
            <div className="col-lg-5">
              <div className="text_container">
                <div className="breadcrumb_text">AWARDS & AFFILIATIONS</div>
                <h4 className="heading">
                  Our Unwavering Endeavour Of Setting New Benchmarks
                </h4>
                <p className="desc">
                  Time Has Won Us Numerous Prestigious Awards :
                </p>
                <ul className="award_list">
                  <li className="list_item">
                    Rashtriya chemicals and fertilizers ltd. award
                  </li>
                  <li className="list_item">
                    Lomon Award for the Best Titanium Dioxide seller of 2013
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
              <div className="certificate_swiper_wrapper">
                <Swiper
                  modules={[Pagination, Navigation]}
                  pagination={true}
                  className="certificate_swiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    993: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  <SwiperSlide className="certificate_slide">
                    <img
                      alt="popawala img"
                      className="certificate_img"
                      width="368"
                      height="432"
                      src={certificate1}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="certificate_slide">
                    <img
                      alt="popawala img"
                      className="certificate_img"
                      width="368"
                      height="432"
                      src={certificate2}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CommonForm />
    </>
  );
};

export default AboutUs;
